import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo.js";
import { Link } from "gatsby";
import sharedStyles from "../components/shared.module.scss";
import buttonStyles from "../components/buttons.module.scss";

const NotFound = () => {
  return (
    <Layout>
      <div className={sharedStyles.centered}>
        <SEO title="Notion Covers | Not Found" />
        <h2>Not Found</h2>
        <p>Sorry, the page you're looking for doesn't exist.</p>
        <Link className={buttonStyles.button} to="/">
          Go home
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
